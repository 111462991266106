<template>
  <el-upload
    name="photoFile"
    class="avatar-uploader"
    :action="uploadUrl"
    :show-file-list="false"
    :headers="headers"
    :data="uploadData"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
    accept="image/png,image/jpeg"
    >
    <div v-if="value" v-loading="loading">
        <img :src="previewUrl" class="avatar"/>
    </div>
    <el-button v-else size="small" type="primary" :loading="loading">点击上传</el-button>
    </el-upload>
</template>
<script>
import Constant from "@/constant";
import { getToken } from "@/utils/auth"; // get token from cookie

export default {
    props: {
        value: {
            type: String,
            default: () => {
                return null;
            }
        },
        subFolder: {            
            type: String,
            default: () => {
                return "sub";
            }
        },
        size: {
            type: Number,
            default: () =>{
                return 64;
            }
        }
    },
    data() { 
        return {       
            uploadUrl: Constant.serverUrl + "/uploadPicture",
            uploadData: {
                subFolder: "",
            },
            headers: {
                Authorization: getToken(),
            },
            loading: false
        }
    },
    computed: {
        previewUrl() {
            return this.value + `?x-oss-process=image/resize,m_lfit,h_${this.size},w_${this.size}`;
        }
    },
    methods: {
        handleAvatarSuccess(res, file) {
            this.loading = false;
            this.$emit("input", res.data);
        },
        beforeAvatarUpload(file) {
            this.loading = true;
            // const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //   this.$message.error("上传图片只能是 JPG 格式!");
            // }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 2MB!");
            }
            return isLt2M;
        }
    },
    mounted() {
        this.uploadData.subFolder = this.subFolder;
    }
}
</script>
<style scoped>
.avatar{
    object-fit: contain;
}
</style>