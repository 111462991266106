
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'150px'">
        <el-form-item label="幻灯片标题" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入幻灯片标题" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="幻灯片分类" prop="classify">
          <el-select v-model="formModel.classify" filterable placeholder="请选择" style="width:300px">
            <el-option
              v-for="item in classifyList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参看人" prop="isTeachers" v-if="formModel.classify=='2'">
            <el-switch  v-model="formModel.isTeachers" 
            active-color="#13ce66" inactive-color="#ff4949"
            active-text="仅班主任可见" inactive-text="所有老师可见"></el-switch>
        </el-form-item>
        <el-form-item label="序号" prop="name">
          <el-input-number v-model="formModel.sortNo" placeholder="请输入序号"></el-input-number>
        </el-form-item>
        <el-form-item label="审核状态" prop="enabled">
            <el-switch  v-model="formModel.enabled" 
            active-color="#13ce66" inactive-color="#ff4949"
            active-text="已审核" inactive-text="未审核"></el-switch>
        </el-form-item>
        <el-form-item label="幻灯片链接地址" prop="linkUrl">
          <el-input v-model="formModel.linkUrl" placeholder="请输入幻灯片链接地址" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="幻灯片图片" prop="picUrl">
          <upload-image v-model="formModel.picUrl" sub-folder="mobileBanner" :size="300"></upload-image>
        </el-form-item>
        <!-- <el-form-item label="对应商品类别" prop="goodsType" v-if="formModel.classify=='1'">
          <el-select v-model="formModel.goodsType" placeholder="请选择" style="width:40%">
            <el-option
              v-for="type in goodsTypeList"
              :key="type.goodsValue"
              :label="type.goodsName"
              :value="type.goodsValue"
            ></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import UploadImage from "@/components/UploadImage";

import mobileBannerInfoApi from "@/api/base/mobileBannerInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        name: [
          { required: true, message: "幻灯片标题不能为空", trigger: "blur" },
        ],
        classify: [
          { required: true, message: "幻灯片分类不能为空", trigger: "blur" },
        ],
        linkUrl: [
          {
            required: true,
            message: "幻灯片链接地址不能为空",
            trigger: "blur",
          },
        ],
        picUrl: [
          { required: true, message: "幻灯片图片不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {},
      classifyList: [],
      goodsTypeList: []
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return mobileBannerInfoApi.add(self.formModel);
            } else {
              return mobileBannerInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  created: function () {
    dataDictionaryApi
      .findByCatalogName({ catalogName: "移动端幻灯片分类" })
      .then((response) => {
        var jsonData = response.data;
        this.classifyList = jsonData.data;
      });

    // import goodsInfoApi from "@/api/base/goodsInfo";
    // goodsInfoApi.goodsTypeList(null).then((response) => {
    //   var jsonData = response.data;
    //   this.goodsTypeList = jsonData.data;
    // });
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return mobileBannerInfoApi.create();
      } else {
        return mobileBannerInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  components: {
    "upload-image": UploadImage
  }
};
</script>