import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/mobileBannerInfo/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/base/mobileBannerInfo/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/mobileBannerInfo/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/mobileBannerInfo/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/mobileBannerInfo/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/mobileBannerInfo/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/mobileBannerInfo/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  pageList,create,edit,add,update,remove,batchRemove
}